<template>
  <vue-final-modal class="modal-restricted-bets" @before-open="updateActiveBonuses">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title" />

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('restricted-bets')" />
      </div>
    </div>

    <div class="vfm__body">
      <atomic-image class="img" :src="getContent(props.currentLocaleData, props.defaultLocaleData, 'image')" />

      <div class="title">
        <template v-if="currentPage === 'sports'">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'sportsbookTitle') }}
        </template>

        <template v-else>{{ getContent(props.currentLocaleData, props.defaultLocaleData, 'casinoTitle') }}</template>
      </div>

      <p class="text">
        <template v-if="currentPage === 'sports'">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'sportsbookDescription') }}
        </template>

        <template v-else>
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'casinoDescription') }}
        </template>
      </p>

      <atomic-bonus-progress
        v-if="activePlayerBonus"
        :wagering-label="getContent(props.currentLocaleData, props.defaultLocaleData, 'wageringLabel')"
        :bonus-info="activePlayerBonus"
      />

      <div class="actions">
        <button-base type="primary" size="md" @click="handleConfirm">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'confirmButton') }}
        </button-base>

        <button-base type="outlined" size="xs" @click="handleCancel">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'cancelButton') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['restrictedBets']>;
    defaultLocaleData: Maybe<CIModalsContent['restrictedBets']>;
  }>();

  const { localizePath, getContent } = useProjectMethods();
  const router = useRouter();
  const route = useRoute();
  const currentPage = route.name === 'sports' || route.name === 'locale-sports' ? 'sports' : 'casino';
  const { closeModal } = useModalStore();

  const bonusStore = useBonusStore();
  const { updateActiveBonuses } = bonusStore;
  const { activePlayerBonus } = storeToRefs(bonusStore);

  watch(activePlayerBonus, newValue => {
    if (!newValue) closeModal('restricted-bets');
  });

  const handleConfirm = async (): Promise<void> => {
    await router.push(localizePath(currentPage === 'sports' ? '/main' : '/sports'));
    await closeModal('restricted-bets');
  };

  const handleCancel = async (): Promise<void> => {
    await router.push(localizePath('/profile/bonuses'));
    await closeModal('restricted-bets');
  };
</script>

<style src="~/assets/styles/components/modal/restricted-bets.scss" lang="scss" />
